import {gsap, Power2, Power3} from 'gsap/all';
import topRotateTitle from '@/_page/topRotateTitle';

export default class topTitle {
  constructor() {
    this.domTitles = document.querySelectorAll('.c-rotateTitle');
    this.maxTitle = this.domTitles.length;
    this.counter = 0;

    const options = {
      // root: null,
      rootMargin: '-25% 0%',
      threshold: 0,
    }
    
    this.rotateTitles = [];
    this.observer = new IntersectionObserver(this.show.bind(this), options);
    this.domTitles.forEach((el, i) => {
      this.rotateTitles.push(new topRotateTitle(el, i));
      el.setAttribute('data-id', i);
      this.observer.observe(el);
    });
  }

  show(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const id = entry.target.getAttribute('data-id');
        this.rotateTitles[id].playOnce();
        entry.target.classList.add('c-rotateTitle--show');
        this.observer.unobserve(entry.target);
        this.counter++;

        if (this.counter === this.maxMV) {
          // すべてのTitle表示完了
          this.observer.disconnect();
          this.observer = null;
          this.rotateTitles = null;
        }
      }
    });
  }

  start() {

  }
}
