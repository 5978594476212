import {gsap, Power2, Power3} from 'gsap/all';

export default class topHashTicker {
  constructor() {
    if (!document.getElementById('p-top__hash')) return;
    this.txtImg = document.querySelector('#p-top__hash img');
    this.txtImg.addEventListener('load', this.loaded.bind(this));
    this.txtImg.src = this.txtImg.getAttribute('data-src');
  }

  loaded(e) {
    const max = 3;
    const width = this.txtImg.width;
		const height = this.txtImg.height;
    this.endX = -width;
    
    this.topHash = document.querySelector('#p-top__hash .c-hash__wrap');
    this.topHash.style.height = `${height}px`;
    this.topHash.style.width = `${width * max}px`;

    for (let i = 1; i < max; i++) {
      const cloneImg = this.txtImg.cloneNode(true)
      cloneImg.style.left = `${width * i}px`;
      this.topHash.appendChild(cloneImg);
    }
    
    // window.addEventListener('resize', this.resize.bind(this));

    // twitterリンク
    this.setLinkSNS();
  }

  setLinkSNS() {
    this.btnLink = document.querySelector('#p-top__hash .c-hash');
    const href = this.btnLink.getAttribute('href');

    if (this.btnLink && href === '#') {
      const ogTitle = document.querySelector("meta[property='og:title']");
      const ogDescription = document.querySelector("meta[property='og:description']");
      const url = 'https://' + location.hostname + '/';
      this.hashTag = this.btnLink.getAttribute('data-hashtag');
  
      this.metaData = {
        title: ogTitle ? ogTitle.getAttribute('content') : '',
        description: ogDescription ? ogDescription.getAttribute('content') : '',
        url: url,
        hashtag: this.hashTag
      };
  
      // twitterリンク
      this.btnLink.setAttribute('href', 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(`${this.metaData.title} ${this.metaData.url} ${this.metaData.hashtag}`));
    }
  }

  start() {
    this.tlLoop = gsap.timeline();
    this.tlLoop.fromTo(this.topHash, {
      x: 0
    }, {
      immediateRender: false,
      duration: 16,
      repeat: -1,
      x: this.endX,
      ease: 'none',
    }, 0);
  }

  resize() {
    console.log('resize');
  }
}
