import {gsap, Power3} from 'gsap/all';

export default class topRotateTitle {
  constructor(title, id) {
    this.id = id;
    this.title = title;
    this.texts = title.querySelectorAll('.is-text')
    this.isHover = false;
    gsap.set(this.texts[1].querySelectorAll('span'), {y: '100%'});
  }

  playOnce() {
    this.isHover = true;
    // gsap.set(this.texts[0].querySelectorAll('span'), {y: '0%'});

    const tl = gsap.timeline({
      onComplete: this.rotateOnceComp.bind(this)
    });
    tl.fromTo(this.texts[1].querySelectorAll('span'), {
      y: '100%',
    }, {
      duration: 0.4,
      y: '0%',
      ease: Power3.easeOut,
      stagger: 0.05,
    });
  }

  rotateOnceComp() {
    this.isHover = false;
  }
}
