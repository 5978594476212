import {gsap, Power2, Power3} from 'gsap/all';

export default class topMv {
  constructor() {
    this.domMVs = document.querySelectorAll('.c-mv');
    this.maxMV = this.domMVs.length;
    this.counter = 0;

    const options = {
      // root: null,
      rootMargin: '-25% 0%',
      threshold: 0,
    }
    
    this.observer = new IntersectionObserver(this.show.bind(this), options);
    this.domMVs.forEach((el) => {
      this.observer.observe(el);
    });
  }

  show(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('c-mv--show');
        this.observer.unobserve(entry.target);
        this.counter++;

        if (this.counter === this.maxMV) {
          // すべてのMV表示完了
          this.observer.disconnect();
          this.observer = null;
        }
      }
    });
  }

  start() {

  }
}
