'use strict';
import topHashTicker from '@/_page/topHashTicker';
import topMv from '@/_page/topMv';
import topTitle from '@/_page/topTitle';

document.addEventListener('DOMContentLoaded', () => {
  gJB.mods['topHashTicker'] = new topHashTicker();
  gJB.mods['topMv'] = new topMv();
  gJB.mods['topTitle'] = new topTitle();
},false);

window.addEventListener('load', () => {
  gJB.mods['topHashTicker'].start();
  gJB.mods['topMv'].start();
  gJB.mods['topTitle'].start();
},false);

window.addEventListener('unload', () => {}, false);
